import {EventEmitter, Injectable} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {EventBusService} from './event-bus.service';
import {ShotDetail} from '../_interfaces/shot';
// import {PopupComponent} from '../_components/_shared/popup/popup.component';
// import {ResShot, Shot} from '../_interfaces/shot';
// import {ShotsService} from './shots/shots.service';

/**
 * This class implements the expansion of the functional carousel shots
 */
@Injectable({
  providedIn: 'root'
})
export class CarouselShareService {
  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param eventBus - This class is required to pass parameters between components.
   */
  constructor(
    // private eventBus: EventBusService,
    // private shotsService: ShotsService,
  ) {}

  onPrev = new EventEmitter<void>();
  onNext = new EventEmitter<void>();

  /** Carousel display option */
  showCarousel = false;
  /** Mobile carousel display option */
  showMobileCarousel = false;
  /** The variable contains an array of selected shots. */
  shots: ShotDetail[] = [];
  /** Carousel Status Parameter */
  carouselState = 'show';

  /** Parameters extending the functionality of the carousel */
  customOptions: OwlOptions = {
    loop: true,
    center: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      375: {
        items: 3
      },
      740: {
        items: 8
      },
      940: {
        items: 8
      },
      1200: {
        items: 8
      }
    },
    nav: true
  };

}

import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

/**
 * This directive is necessary to track a click outside a certain element (used to close the menu that opens)
 */
@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  /** Class representing outside directive.
   * @param _elementRef
   */
  constructor(private _elementRef: ElementRef) {
  }

  /** Event @Output for click outside  */
  @Output()
  public appClickOutside = new EventEmitter<MouseEvent>();

  /** Event @HostListener for click outside  */
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    /** Function for emit click outside event.
     * @param {MouseEvent} event.
     * @param {HTMLElement} targetElement.
     */
    // console.log('directive CloseOutSide');
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.appClickOutside.emit(event);
    }
  }
}

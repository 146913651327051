import {Injectable} from '@angular/core';
import {ShotDetail} from '../../_interfaces/shot';

@Injectable({
  providedIn: 'root'
})
export class ShotsStoreService {

  /** Variable for passing information about window position */
  windowPosition: number;
  /** Variable for passing information about the array of shots */
  shots: ShotDetail[] = [];
  /** Page number */
  pageNum: number;
  /** Parameter to establish the first fake shot (All) */
  startShotAll: number;
  /** Parameter to establish the first fake shot (Web) */
  startShotWeb: number;
  /** Parameter to establish the first fake shot (Mobile) */
  startShotMobile: number;

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ResShot, ResShots} from '../../_interfaces/shot';

/**
 * This class implements getting information about shots.
 */
@Injectable({
  providedIn: 'root'
})
export class ShotsService {

  /** Url to the API. */
  apiURL = environment.apiUrl;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param httpClient - This class is required to create http requests.
   */
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Method to get shots
   * @param params - parameters about the current page, platform, number of shots per page
   */
  getShots(params) {
    // https://api.mobile.design/api/shots?page=1&tags=&platform=&per_page=20
    params = params.append('cached', 'true');
    return this.httpClient.get<ResShots>(`${this.apiURL}/api/shots`, {params});
  }

  /**
   * Method to get carousel shots
   */
  getShotsCarousel() {
    let params = new HttpParams();
    params = params.append('page', `1`);
    params = params.append('tags', ``);
    params = params.append('platform', ``);
    params = params.append('per_page', `50`);
    // params = params.append('cached', 'true');
    return this.httpClient.get<ResShots>(`${this.apiURL}/api/shots`, {params});
  }

  /**
   * Method for getting a shot, as well as getting information about the next and previous shot
   * @param shotId - shot id
   * @param categoryId - category id
   * @param platform - filtration platform (web, mobile, all)
   */
  getShot(shotId: number, categoryId: string | number, platform: string) {
    // https://api.mobile.design/api/shots/453/show_three?platform=
    let params = new HttpParams();
    params = params.append('platform', platform);
    params = params.append('collection_id', String(categoryId));
    return this.httpClient.get<ResShot>(`${this.apiURL}/api/shots/${shotId}/show_three`, {params});
  }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubscribeService} from '../../_services/subscribe/subscribe.service';
import {MatDialogRef} from '@angular/material';

/**
 * This class implements the display of a modal window with the form subscribe to updates.
 */
@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  /** Declaring a variable based on an instance of the FormGroup class */
  form: FormGroup;
  /** Parameter to display the error */
  errorMessage: string;
  /** Parameter to display the sending of a message */
  sendSuccess = false;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param formBuilder - This class is required to create FormControl instances, which reduces form creation.
   * @param subscribeService - service for transferring subscriber data to server
   * @param dialogRef - class for modal window functionality
   */
  constructor(
    private formBuilder: FormBuilder,
    private subscribeService: SubscribeService,
    private dialogRef: MatDialogRef<SubscribeComponent>,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    this.initializeForm();
  }

  /**
   * This method initializes form creation.
   */
  initializeForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  /**
   * This method sends data from the form to the service.
   */
  send() {
    if (this.form.valid) {
      this.form.disable();
      this.subscribeService.postSubscribe(this.form.value)
        .subscribe(
          () => {
            console.log('subscribe send');
            this.sendSuccess = true;
          },
          error => {
            this.form.enable();
            this.sendSuccess = false;
            console.log(error.error.error);
            this.errorMessage = error.error.error;
          });
    } else {
      this.form.enable();
      this.sendSuccess = false;
      this.errorMessage = 'Please fill out the form correctly';
    }
  }

  /**
   * Method to close the modal window
   */
  onClose() {
    this.dialogRef.close();
  }

}

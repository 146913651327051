import {Component, OnInit, ViewChild} from '@angular/core';
import {CookiesService} from './_services/cookies/cookies.service';
import {CarouselShareService} from './_services/carousel-share.service';
import {carouselState} from './_animations/carouselState';
import {CarouselComponent} from 'ngx-owl-carousel-o';
import {PopupControlService} from './_services/popup-control/popup-control.service';

/**
 * This class is designed to display data on the site. It displays all other components.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'mobile-design';

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param allowCookieService - service to get information about visiting the site
   * @param carouselShareService - service to expand the functionality of the carousel of shots
   */
  constructor(
    private allowCookieService: CookiesService,
    public carouselShareService: CarouselShareService,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    this.allowCookieService.getVisiting();
  }
}

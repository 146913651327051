import {EventEmitter, Injectable} from '@angular/core';
import {ShotDetail} from '../_interfaces/shot';

/**
 * This class implements the event bus.
 */
@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  /** Variable information about the shots */
  shots: ShotDetail[] = [];
  /** Variable for passing information about shot id */
  shot: EventEmitter<number> = new EventEmitter();
  /** Variable for a full shot description */
  fullDescription = false;
  /** Variable for a category id */
  categoryId: string;
  /** Variable for a platform name */
  platform: string;
  /** Parameter for sharing disclosure data collapse */
  collapseRef;
  /** Parameter for sharing disclosure data collapse */
  contentRef;
  /** Option to open contact form */
  showForm: boolean;
  /** Parameter for sharing disclosure data collapse */
  popupDescrRef;
  /** Parameter for sharing disclosure data collapse */
  descrMoreRef;
  /** Variable for selected shot */
  selectedShot: ShotDetail;


  /**
   * Method to change carousel shot from components
   * @param shot - shot data
   */
  setShotData(shot: ShotDetail): void {
    this.selectedShot = shot;
  }

  /**
   * Method to get category data from component
   * @param categoryId - id of category
   * @param platform - platform of category
   */
  setCategoryData(categoryId: string, platform) {
    this.categoryId = categoryId;
    this.platform = platform;
  }

  /**
   * A method that receives a status about folding and expanding a list for transfer to another component.
   * @param toggle
   * @param content
   */
  setToggleHire(toggle, content) {
    this.collapseRef = toggle;
    this.contentRef = content;
  }

  /**
   * The method that receives the status of the form after sending the data
   * @param show
   */
  setShowForm(show) {
    this.showForm = show;
  }

  /**
   * A method that receives a status about showing a full shot description.
   * @param show
   */
  setFullDescription(show) {
    this.fullDescription = show;
  }

}

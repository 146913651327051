import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {VideoService} from '../../../_services/video/video.service';
import {EventBusService} from '../../../_services/event-bus.service';
import {FeedbackService} from '../../../_services/feedback/feedback.service';

/**
 * This class is designed to display the collapse of a component inside a modal window.
 */
@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit, AfterViewInit {
  /** This parameter creates a reference link to the html element. */
  @ViewChild('collapse') collapseRef: ElementRef;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('content') contentRef: ElementRef;
  /** This parameter creates a reference link to the html video element. */
  @ViewChild('videoElem') videoRef: ElementRef;
  /** Input parameter from other components */
  @Input() title;
  /** Input parameter from other components */
  @Input() description;
  /** Input parameter from other components */
  @Input() fontSize;
  /** Input parameter from other components */
  @Input() fontColor;
  /** Input parameter from other components */
  @Input() topArrow;
  /** Current tab parameter */
  curTab;
  /** Parameter to display the sending of a message */
  sendSuccess: boolean;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param videoService - This service for getting data about the video file
   * @param eventBusService - This service transfers data between components.
   * @param feedBackService - This service for sending information about the sending of another message
   */
  constructor(
    public videoService: VideoService,
    private eventBusService: EventBusService,
    public feedBackService: FeedbackService,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    this.videoService.getMainVideo();
    this.eventBusService.setToggleHire(this.contentRef, this.contentRef);
  }

  /**
   * This lifecycle interface is used during component after initialization.
   */
  ngAfterViewInit() {
      setTimeout(() => {
        const contaner = document.querySelector('.cdk-overlay-container');
        if (contaner) {
          contaner.classList.add('active');
        }
      }, 300);
  }

  /**
   * This method allows you to open a form after sending a message.
   */
  openForm() {
    this.feedBackService.setSuccess(this.sendSuccess = false);
  }

  /**
   * This method is intended to expand the component list.
   */
  openSlideMenu() {
    this.openForm();
    this.collapseRef.nativeElement.classList.toggle('collapse__title--active');

    this.curTab = this.collapseRef.nativeElement.parentNode.parentNode;
    if (this.curTab.getAttribute('id') === 'showreel') {
      this.videoRef.nativeElement.pause();
    }

    if (this.contentRef.nativeElement.style.height) {
      this.contentRef.nativeElement.style.height = null;
    } else {
      console.log(this.contentRef.nativeElement.scrollHeight);
      this.contentRef.nativeElement.style.height = this.contentRef.nativeElement.scrollHeight + 'px';
    }
    if (this.eventBusService.showForm) {
      this.contentRef.nativeElement.scrollIntoView();
    }
  }

}

import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

/**
 * This directive is used to display another image when you hover the mouse.
 */
@Directive({
  selector: '[appFreezeVideo]'
})
export class FreezeVideoDirective implements AfterViewInit {
  /** Original Image Option */
  @Input() videoOriginal: string;

  video;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param el - connecting class to create reference element references
   */
  constructor(
    private el: ElementRef
  ) {
  }

  /**
   * This lifecycle interface is called after the component is initialized.
   */
  ngAfterViewInit() {
    this.video = this.el.nativeElement;
    this.video.addEventListener('mouseout', this.onVideoMouseout);
    this.video.addEventListener('mouseover', this.onVideoMouseover);
  }

  /**
   * The method implements mouse hover behavior.
   * @param event
   */
  private onVideoMouseover = (event) => {
    // event.target.src = this.videoOriginal;
    // console.log(event);
    this.video.load();
    const playPromise = this.video.play();
    if (playPromise !== null) {
      playPromise
        .then(() => {
          this.video.play();
        })
        .catch(() => {
          this.video.pause();
        });
    }
  };

  /**
   * The method implements the behavior after the mouse hovers.
   * @param event
   */
  private onVideoMouseout = (event) => {
    const pausePromise = this.video.pause();
    if (pausePromise !== undefined) {
      pausePromise
        .then(() => {
          this.video.pause();
        })
        .catch(error => console.log(error));
    }
  };

}

import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

/**
 * This directive is used to display another image when you hover the mouse.
 */
@Directive({
  selector: '[appFreezeGif]'
})
export class FreezeGifDirective implements AfterViewInit {
  /** Original Image Option */
  @Input() imageOriginal: string;

  /** Variable to keep the original image */
  private imageThumbnail = '';

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param el - connecting class to create reference element references
   */
  constructor(
    private el: ElementRef
  ) {
  }

  /**
   * This lifecycle interface is called after the component is initialized.
   */
  ngAfterViewInit() {
    const image = this.el.nativeElement;
    image.addEventListener('mouseout', this.onGifMouseout);
    image.addEventListener('mouseover', this.onGifMouseover);
  }

  /**
   * The method implements mouse hover behavior.
   * @param event
   */
  private onGifMouseover = (event) => {
    this.imageThumbnail = event.target.src;
    event.target.src = this.imageOriginal;
  };

  /**
   * The method implements the behavior after the mouse hovers.
   * @param event
   */
  private onGifMouseout = (event) => {
    event.target.src = this.imageThumbnail;
  };

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CategoriesService} from '../../_services/categories/categories.service';


import {Router} from '@angular/router';
import {CookiesService} from '../../_services/cookies/cookies.service';
import {ShotsStoreService} from '../../_services/shots-store/shots-store.service';


@Component({
  selector: 'app-mob-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./_categories.component.scss']
})

export class CategoriesComponent implements OnInit {

  @ViewChild('showTip') showTipRef: ElementRef;

  defaultParams = {
    per_page: 100
  };

  collections = [];
  collectionsId = [];

  showWalkthrough: boolean;
  randomNumber: number;

  constructor(private router: Router,
              private categoriesService: CategoriesService,
              private allowCookieService: CookiesService,
              private shotsStoreService: ShotsStoreService,
  ) {
  }

  ngOnInit() {
    this.shotsStoreService.windowPosition = 0;
    this.shotsStoreService.startShotAll = 0;
    this.shotsStoreService.shots = [];
    setInterval(() => {
      if (document.querySelector('.collections__collection-wrapper.original')) {
        [].forEach.call(
          document.querySelectorAll('.preload__wrapper'),
          element => element.classList.add('disabled'),
        );
      }
    }, 100);
    // console.log(this.allowCookieService.tipCategories);
    // console.log(this.allowCookieService.walkthrough);
    if (!this.allowCookieService.walkthrough) {
      this.showWalkthrough = this.allowCookieService.walkthrough;
    } else {
      this.showWalkthrough = this.allowCookieService.tipCategories;
    }
    this.getCategories();
  }

  getCategories() {
    this.categoriesService.getCategories(this.defaultParams.per_page).subscribe((response: CategoriesComponent) => {
      for (const collection of response.collections) {
        collection.link_title = collection.title.toLowerCase().split(' ').join('');
        this.collections.push(collection);
      }
      // console.log(this.collections);
      this.collections.forEach(item => {
        this.collectionsId.push(item.id);
      });
      this.randomNumber = this.collectionsId[Math.floor(Math.random() * this.collectionsId.length)];
      // console.log(this.randomNumber);
    });
  }

  toCollection() {
    this.showWalkthrough = false;
    this.allowCookieService.setTipCategories(false);
    this.router.navigateByUrl(`/categories/${this.randomNumber}`);
  }

  closeTip() {
    this.showTipRef.nativeElement.style.display = 'none';
    this.allowCookieService.setVisiting();
  }
}

import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ShotDetail} from '../../../../_interfaces/shot';

@Component({
  selector: 'app-default-shot',
  templateUrl: './default-shot.component.html',
  styleUrls: ['./default-shot.component.scss']
})
export class DefaultShotComponent implements OnInit, OnChanges {

  @Input() shot: ShotDetail;

  @ViewChild('shotImage') shotImageRef: ElementRef<HTMLImageElement>;

  isGif = false;
  shotGifSrc: string;
  shotImgSrc: string;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shot']) {
      // console.log(this.shot);
      if (this.isGifImage(this.shot.images[0].image_content_type)) {
        this.isGif = true;
        this.shotGifSrc = this.shot.images[0].original;
        this.shotImgSrc = this.shot.images[0].sm_1;
      }
    }
  }

  @HostListener('mouseover') onMouseOver() {
    if (this.isGif) {
      this.shotImageRef.nativeElement.src = this.shotGifSrc;
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.isGif) {
      this.shotImageRef.nativeElement.src = this.shotImgSrc;
    }
  }

  /**
   * This method implements shot checking on a gif image.
   * @param src - the variable takes the parameter shot.images[0].image_content_type
   */
  isGifImage(src: string): boolean {
    return /^(?!data:).*gif/i.test(src);
  }

}

import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

/**
 * This class implements getting information about a site visit.
 */
@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  /** The option to display tips on the site */
  walkthrough: boolean;
  /** The option to display tips on the main page */
  tipHeader = true;
  /** The option to display tips on the category display page */
  tipCategories = true;
  /** The option to display the tooltip on a page of a specific category */
  tipCategory = true;
  /** Parameter for displaying the tooltip in the modal window of the selected shot */
  tipPopup = true;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param cookieService - This class is necessary for the service to work.
   */
  constructor(
    private cookieService: CookieService,
  ) {
  }

  /**
   * Setting a parameter about visiting the site
   */
  setVisiting() {
    this.cookieService.set('walkthrough', `false`);
  }

  /**
   * Get the status of the parameter about visiting the site
   */
  getVisiting() {
    // hide tips
    this.walkthrough = false;
    // (this.cookieService.get('walkthrough') === '') ? this.walkthrough = true : this.walkthrough = false;
  }

  /**
   * Setting the visiting parameter from the categories list page
   * @param params - true or false
   */
  setTipCategories(params: boolean) {
    this.tipCategories = params;
  }

  /**
   * Setting a visit option from a separate category page
   * @param params - true or false
   */
  setTipCategory(params: boolean) {
    this.tipCategory = params;
  }

  /**
   * Setting the visiting parameter from the main page
   * @param params - true or false
   */
  setTipHeader(params: boolean) {
    this.tipHeader = params;
  }

  /**
   * Setting the visiting parameter from the modal window
   * @param params - true or false
   */
  setTipPopup(params: boolean) {
    this.tipPopup = params;
  }

}

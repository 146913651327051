import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';

/**
 * This class implements working with modal windows
 */
@Injectable({
  providedIn: 'root'
})
export class CreatePopupService {

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param matDialog - class for modal window functionality
   */
  constructor(
    private matDialog: MatDialog,
  ) {
  }

  /**
   * Method for creating a modal window
   * @param component - the name of the component for which the modal window is being created
   */
  onCreatePopup(component) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80%';
    // dialogConfig.disableClose = true;
    this.matDialog.open(component, dialogConfig);
  }

  /**
   * Method of closing a modal window
   */
  closeModal() {
    this.matDialog.closeAll();
  }
}

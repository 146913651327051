import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';

/**
 * Interface implementation for cached data
 */
export interface CachedData {
  /** Data caching time */
  time: number;
  /** Cached data body */
  body: object;
}

/**
 * This class implements the caching of requests in sessionStorage
 */
@Injectable({
  providedIn: 'root'
})
export class RequestCachingService {

  /** Variable to check if data can be saved */
  private hasSessionStorage: boolean;

  /** The length of the cached value in bytes */
  private sessionStorageItemMaxBytes = 256192;

  /** Variable for information about the request in the session */
  private httpMadeStory: { [key: string]: boolean } = {};

  /** Cacheability (default is false) */
  cached = false;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   */
  constructor() {
    this.checkSessionStorage();
  }

  /**
   * The method saves data in sessionStorage with the current time
   * @param key - Key values in sessionStorage
   * @param bodyCache - Stored data
   */
  saveDataInSessionStorage(key, bodyCache) {
    if (!this.hasSessionStorage || !key || !bodyCache) {
      return;
    }
    const cachedData: CachedData = {
      time: Date.now(),
      body: bodyCache,
    };
    // console.log(cachedData);

    const data = JSON.stringify(cachedData);

    if (data.length < this.sessionStorageItemMaxBytes) {
      sessionStorage.setItem(key, JSON.stringify(cachedData));
    } else {
      delete this.hasSessionStorage[key];
    }
  }

  /**
   * The method returns the value stored in sessionStorage for the specified key
   * @param key - SessionStorage key
   */
  getDataFromSessionStorage(key): CachedData['body'] {
    if (!this.hasSessionStorage) {
      return;
    }

    let result = null;
    const cachedDataJson = sessionStorage.getItem(key);

    if (cachedDataJson) {
      const cachedData = JSON.parse(cachedDataJson) as CachedData;
      if (cachedData.body) {
        result = cachedData.body;
      }
    }
    return result;
  }

  /**
   * The method generates a cache key based on the http request
   * @param req - An object with the description of the body of the http request
   */
  getRequestKey(req: HttpRequest<any>): string {
    return req.body ? JSON.stringify(req.body) : req.urlWithParams || req.url;
  }

  /**
   * The method determines that the specified request has already been executed within one session
   * @param storageKey
   */
  setHttpStoryMade(storageKey): void {
    this.httpMadeStory[storageKey] = true;
  }

  /**
   * The method checks whether sessionStorage is available on the client and saves
   */
  private checkSessionStorage(): void {
    try {
      const test = 'test';
      sessionStorage.setItem(test, test);
      sessionStorage.removeItem(test);
      this.hasSessionStorage = true;
    } catch (e) {
      this.hasSessionStorage = false;
    }
  }
}

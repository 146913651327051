import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Category} from '../../_interfaces/category';
import {ResShots} from '../../_interfaces/shot';

/**
 * This class implements retrieving category information from the server
 */
@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  /** Url to the API. */
  apiURL = environment.apiUrl;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param httpClient - This class is required to create http requests.
   */
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Method to get carousel shots from selected category
   * @param id - Category ID
   * @param platform - category filtering platform (web, mobile, all)
   */
  getOneCategoryCarousel(id, platform) {
    let params = new HttpParams();
    params = params.append('page', `1`);
    params = params.append('platform', `${platform}`);
    params = params.append('per_page', `50`);
    // params = params.append('cached', 'true');
    return this.httpClient.get<ResShots>(`${this.apiURL}/api/collections/${id}/shots`, {params});
  }


  /**
   * Method for listing categories
   * @param perPage - number of categories per page
   */
  getCategories(perPage) {
    let params = new HttpParams();
    params = params.append('per_page', `${perPage}`);
    params = params.append('cached', 'true');
    return this.httpClient.get(`${this.apiURL}/api/collections` , {params});
  }

  /**
   * Method to get shots in selected category
   * @param id - Category ID
   * @param platform - category filtering platform (web, mobile, all)
   * @param pageNum - page number
   * @param perPage - number of shots per page
   */
  getOneCategory(id, platform, pageNum, perPage) {
    let params = new HttpParams();
    params = params.append('platform', `${platform}`);
    params = params.append('page', `${pageNum}`);
    params = params.append('per_page', `${perPage}`);
    params = params.append('cached', 'true');
    return this.httpClient.get<ResShots>(`${this.apiURL}/api/collections/${id}/shots`, {params});
  }

  /**
   * Method to get information about the selected category (avatar, banner, description, name)
   * @param id - Category ID
   */
  getInfo(id) {
    let params = new HttpParams();
    params = params.append('cached', 'true');
    return this.httpClient.get<Category>(`${this.apiURL}/api/collections/${id}`, {params});
  }

}

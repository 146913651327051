import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CreatePopupService} from '../../../_services/create-popup/create-popup.service';
import {DiscussComponent} from '../discuss/discuss.component';
import {VideoComponent} from '../video/video.component';
import {AboutUsComponent} from '../about-us/about-us.component';
import {VideoService} from '../../../_services/video/video.service';
import {Router} from '@angular/router';
import {CookiesService} from '../../../_services/cookies/cookies.service';
import {FeedbackService} from '../../../_services/feedback/feedback.service';

/**
 * This class implements a header component.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /** This parameter creates a reference link to the html element. */
  @ViewChild('slideMenu') slideMenuRef: ElementRef;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('menuToggle') menuToggleRef: ElementRef;

  /** Option to display hints */
  showWalkthrough: boolean;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param createPopup - service for working with a modal window
   * @param videoService - service for getting data about the video file
   * @param router - An NgModule that provides navigation and URL manipulation capabilities
   * @param allowCookieService - service to get information about visiting the site
   * @param feedBackService - service for sending information about the sending of another message
   * @param renderer - extend this base class to implement custom rendering
   */
  constructor(
    private createPopup: CreatePopupService,
    public videoService: VideoService,
    private router: Router,
    private allowCookieService: CookiesService,
    public feedBackService: FeedbackService,
    private renderer: Renderer2
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    if (!this.allowCookieService.walkthrough) {
      this.showWalkthrough = this.allowCookieService.walkthrough;
    } else {
      this.showWalkthrough = this.allowCookieService.tipCategories;
    }
    this.videoService.getMainVideo();
  }

  /**
   * This method implements the creation of the Discuss modal window.
   */
  onDiscuss() {
    this.renderer.addClass(document.body, 'modal-open');
    this.feedBackService.setSuccess(false);
    this.createPopup.onCreatePopup(DiscussComponent);
  }

  /**
   * This method implements the creation of a modal window Showreel
   */
  onVideo() {
    this.createPopup.onCreatePopup(VideoComponent);
  }

  /**
   * This method implements the creation of a modal window About Us
   */
  onAbout() {
    this.renderer.addClass(document.body, 'modal-open');
    this.createPopup.onCreatePopup(AboutUsComponent);
  }

  /**
   * Method to open menu on mobile
   */
  openSlideMenu() {
    this.menuToggleRef.nativeElement.classList.toggle('menu__toggle--active');
    if (this.slideMenuRef.nativeElement.style.height) {
      this.slideMenuRef.nativeElement.style.height = null;
    } else {
      this.slideMenuRef.nativeElement.style.height = this.slideMenuRef.nativeElement.scrollHeight + 'px';
    }
  }

  /**
   * Method to close menu on mobile
   */
  closeMenu() {
    this.menuToggleRef.nativeElement.classList.remove('menu__toggle--active');
    this.slideMenuRef.nativeElement.style.height = null;
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {CarouselShareService} from '../../_services/carousel-share.service';
import {PopupControlService} from '../../_services/popup-control/popup-control.service';
import {CarouselComponent} from 'ngx-owl-carousel-o';
import {carouselState} from '../../_animations/carouselState';

@Component({
  selector: 'app-shot-carousel',
  templateUrl: './shot-carousel.component.html',
  styleUrls: ['./shot-carousel.component.scss'],
  animations: [carouselState]
})
export class ShotCarouselComponent implements OnInit {

  private owlSlider: CarouselComponent;

  @ViewChild('owlSlider') set content(content: CarouselComponent) {
    this.owlSlider = content;
  }

  constructor(
    public carouselShareService: CarouselShareService,
    public popupControlService: PopupControlService,
  ) { }

  ngOnInit() {
    this.carouselShareService.onPrev
      .subscribe(() => this.owlSlider.prev());
    this.carouselShareService.onNext
      .subscribe(() => this.owlSlider.next());
  }

}

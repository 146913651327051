import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';

import {ShotsService} from '../../../_services/shots/shots.service';
import {EventBusService} from '../../../_services/event-bus.service';
import {CookiesService} from '../../../_services/cookies/cookies.service';
import {FeedbackService} from '../../../_services/feedback/feedback.service';
import {Shot, ShotDetail} from '../../../_interfaces/shot';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {PlatformService} from '../../../_services/platform.service';
import {CarouselShareService} from '../../../_services/carousel-share.service';
import {PopupControlService} from '../../../_services/popup-control/popup-control.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('showTip') showTipRef: ElementRef;
  @ViewChild('form') formRef: ElementRef;
  @ViewChild('popupDescr') popupDescrRef: ElementRef;
  @ViewChild('descrMore') descrMoreRef: ElementRef;

  showWalkthrough: boolean;

  shots: ShotDetail[] = [];
  shot: ShotDetail;
  shotNext: ShotDetail;
  shotPrev: ShotDetail;

  nextId: number;
  prevId: number;

  sendSuccess: boolean;
  countChar = 150;
  countCharDesktop = 300;

  scrollElement: HTMLElement;
  carouselState = 'show';
  lastScrollTop = Infinity;
  scrollSubscription: Subscription;
  eventBusSubscription: Subscription;

  randomNumber: number;
  shotId: number;
  collectionId;

  constructor(
    private dialogRef: MatDialogRef<PopupComponent>,
    private activatedRoute: ActivatedRoute,
    private shotsService: ShotsService,
    public eventBusService: EventBusService,
    private allowCookieService: CookiesService,
    public feedBackService: FeedbackService,
    private platformService: PlatformService,
    private carouselShareService: CarouselShareService,
    public popupControlService: PopupControlService,
    // private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    if (!this.allowCookieService.walkthrough) {
      this.showWalkthrough = this.allowCookieService.walkthrough;
    } else {
      this.showWalkthrough = this.allowCookieService.tipPopup;
    }

    this.eventBusSubscription = this.eventBusService.shot.subscribe(id => {
      this.popupControlService.getShot(id);
    });

    // this.carouselShareService.shots = this.eventBusService.shots;

    this.eventBusService.popupDescrRef = this.popupDescrRef;
    this.eventBusService.descrMoreRef = this.descrMoreRef;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.carouselShareService.showCarousel = true;
    }, 0);

    // TODO: This is bad implementation, but for now it's required a quick solution
    this.scrollElement = document.querySelector('.cdk-global-overlay-wrapper');

    this.lastScrollTop = this.scrollElement.scrollTop;
    this.scrollSubscription = fromEvent(this.scrollElement, 'scroll')
      .pipe(
        debounceTime(300),
      )
      .subscribe(() => {
        const st = this.scrollElement.scrollTop;
        // console.log('scroll');

        if (st > this.lastScrollTop) {
          // console.log('hide');
          this.carouselShareService.carouselState = 'hide';
        } else {
          // console.log('show');
          this.carouselShareService.carouselState = 'show';
        }
        this.lastScrollTop = st <= 0 ? 0 : st;
      });
  }

  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
    this.carouselShareService.showCarousel = false;
    this.eventBusSubscription.unsubscribe();
  }

  scrollElem() {
    this.scrollElement.scrollTo(0, 0);
    this.randomNumber = Math.floor(Math.random() * 12);
    this.eventBusService.setFullDescription(false);
  }

  showMoreTip() {
    if (window.innerWidth <= 700) {
      if (this.popupDescrRef.nativeElement.clientHeight > 86) {
        this.popupDescrRef.nativeElement.classList.add('description--disabled');
        this.popupDescrRef.nativeElement.style.webkitBoxOrient = 'vertical';
        this.descrMoreRef.nativeElement.style.display = 'block';
      }
    }
  }

  closeWindow() {
    // this.renderer.removeClass(document.body, 'modal-open');
    this.eventBusService.setFullDescription(false);
    this.feedBackService.setSuccess(false);
    this.dialogRef.close();
    document.querySelector('.cdk-overlay-container').classList.remove('active');
  }

  toHireUs() {
    this.allowCookieService.setTipPopup(false);
    this.allowCookieService.setVisiting();

    setTimeout(() => {
      this.formRef.nativeElement.scrollIntoView();
    }, 500);

    const contentRef = this.eventBusService.contentRef;
    if (contentRef.nativeElement.style.height) {
      contentRef.nativeElement.style.height = null;
    } else {
      contentRef.nativeElement.style.height = contentRef.nativeElement.scrollHeight + 'px';
    }
    this.showWalkthrough = false;
    document.querySelector('#hire .collapse__title').classList.add('collapse__title--active');
  }

  closeTip() {
    this.allowCookieService.setTipPopup(false);
    this.showTipRef.nativeElement.style.display = 'none';
    this.allowCookieService.setVisiting();
  }

  openForm() {
    this.feedBackService.setSuccess(this.sendSuccess = false);
  }

  showMore() {
    this.eventBusService.setFullDescription(true);
  }

  hideMore() {
    this.eventBusService.setFullDescription(false);
  }

  nextSlide() {
    this.carouselShareService.onNext.emit();
  }

  prevSlide() {
    this.carouselShareService.onPrev.emit();
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-single-category',
  templateUrl: './single-category.component.html',
  styleUrls: ['./_single-category.component.scss']
})

export class SinglecategoryComponent implements OnInit {

  @Input() collection;

  constructor() {
  }

  ngOnInit() {
  }
}

import {animate, state, style, transition, trigger} from '@angular/animations';

/**
 * Implementing Carousel State Animation
 */
export let carouselState =
  trigger('carouselState', [
    state('show', style({
      transform: 'translateY(0)'
    })),
    state('hide', style({
      transform: 'translateY(500px)'
    })),
    transition('hide <=> show', animate('300ms ease-out'))
  ]);

import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent implements OnInit, OnDestroy {

  @ViewChild('scrollToTop') scrollToTopRef: ElementRef<HTMLDivElement>;

  scrollSubscription: Subscription;
  /** Option to create a scroll instance */
  scroll = new Subject();

  constructor() { }

  ngOnInit() {
    this.scrollSubscription = this.scroll
      .pipe(debounceTime(500))
      .subscribe(() => {
        (window.pageYOffset > 3000) ?
          this.scrollToTopRef.nativeElement.classList.remove('top_scroll--hidden') :
          this.scrollToTopRef.nativeElement.classList.add('top_scroll--hidden');
      });
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  /** Event @HostListener for window scroll */
  @HostListener('window:scroll')
  onScroll() {
    this.scroll.next();
  }

  /** Method for scrolling to top */
  scrollTop(): void {
    window.scrollTo(0, 0);
  }

}

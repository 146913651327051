import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {DiscussComponent} from '../discuss/discuss.component';
import {CreatePopupService} from '../../../_services/create-popup/create-popup.service';
import {AboutUsComponent} from '../about-us/about-us.component';

/**
 * This class implements the display of the bottom of the site (footer)
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  /** This parameter creates a reference link to the html element. */
  @ViewChild('modal') modalRef: ElementRef;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param createPopup - service for working with a modal window
   * @param renderer - extend this base class to implement custom rendering
   */
  constructor(
    private createPopup: CreatePopupService,
    private renderer: Renderer2
  ) {
  }

  /**
   * This method implements blocking background scrolling when displaying a modal window.
   */
  addedModal() {
    this.renderer.addClass(document.body, 'modal-open');
  }

  /**
   * This method implements the creation of the Discuss modal window.
   */
  onDiscuss() {
    this.addedModal();
    this.createPopup.onCreatePopup(DiscussComponent);
  }

  /**
   * This method implements the creation of a modal window About Us
   */
  onAbout() {
    this.addedModal();
    this.createPopup.onCreatePopup(AboutUsComponent);
  }

  /**
   * This method implements the display of a modal window of term of use
   */
  onTerms() {
    this.modalRef.nativeElement.style.display = 'block';
    this.addedModal();
  }

  /**
   * This method implements closing the term of use modal window
   */
  closeModal() {
    this.modalRef.nativeElement.style.display = 'none';
    this.renderer.removeClass(document.body, 'modal-open');
  }

}

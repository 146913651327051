import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ResShot, ShotDetail} from '../../../_interfaces/shot';
import {ActivatedRoute, Router} from '@angular/router';
import {ShotsService} from '../../../_services/shots/shots.service';
import {EventBusService} from '../../../_services/event-bus.service';
import {PlatformService} from '../../../_services/platform.service';
import {CarouselShareService} from '../../../_services/carousel-share.service';
import {CategoriesService} from '../../../_services/categories/categories.service';
import {VideoService} from '../../../_services/video/video.service';
import {FeedbackService} from '../../../_services/feedback/feedback.service';
import {NavigationService} from '../../../_services/navigation.service';
import {Subscription} from 'rxjs';

/**
 * This class is designed to display information about the selected shot in the mobile version.
 */
@Component({
  selector: 'app-shot-mobile',
  templateUrl: './shot-mobile.component.html',
  styleUrls: ['./shot-mobile.component.scss']
})
export class ShotMobileComponent implements OnInit, OnDestroy {

  /** This parameter creates a reference link to the html element. */
  @ViewChild('popupDescr') popupDescrRef: ElementRef;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('descrMore') descrMoreRef: ElementRef;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('target') targetRef: ElementRef;

  /** Variable for passing information about the array of shots */
  shots: ShotDetail[] = [];
  /** Variable contains information about the shot */
  shot: ShotDetail;
  /** This parameter contains information about the next shot. */
  shotNext: ShotDetail;
  /** This parameter contains information about the previous shot. */
  shotPrev: ShotDetail;
  /** Parameter to display the sending of a message */
  sendSuccess: boolean;
  /** Number of characters displayed in the mobile version */
  countChar = 150;
  /** The number of characters displayed in the desktop version */
  countCharDesktop = 300;
  // /** List of colors for the background */
  // colors = ['8c99ff', 'ff8c89', 'ffef85', '84fbff', '8bf5ff', 'ffca87', 'a486ff', 'ffa188', 'de86ff', 'a2fe86', 'ff8bde', '97ffc4'];
  /** Parameter defines random color */
  randomNumber: number;
  /** This option hides the display of showreel */
  hiddenShowreel = true;
  /** This option hides the display of hire us */
  hiddenHireUs = true;
  shotID: number;
  routeSubscription: Subscription;
  categorySubscription: Subscription;
  shotSubscription: Subscription;
  eventBusSubscription: Subscription;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param activatedRoute - Contains the information about a route associated with a component loaded in an outlet.
   * @param shotsService - This service sends a request for shots to the server.
   * @param eventBusService - This service transfers data between components.
   * @param feedBackService - This service for sending information about the sending of another message
   * @param platformService - Service for transferring data between components
   * @param carouselShareService - This service to expand the functionality of the carousel of shots
   * @param router - An NgModule that provides navigation and URL manipulation capabilities.
   * @param categoryService - Service to get category data
   * @param videoService - This service for getting data about the video file
   * @param navService - This service is designed to transfer the data of the route between the components.
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private shotsService: ShotsService,
    public eventBusService: EventBusService,
    public feedBackService: FeedbackService,
    private platformService: PlatformService,
    private carouselShareService: CarouselShareService,
    private router: Router,
    private categoryService: CategoriesService,
    public videoService: VideoService,
    private navService: NavigationService,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    this.carouselShareService.showMobileCarousel = true;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      const shotId = params.shotId;
      const collectionId = params.collectionsId;
      const categoryPlatform: string = this.platformService.selectedPlatform;
      if (params.collectionsId) {
        this.categorySubscription = this.categoryService.getOneCategoryCarousel(collectionId, categoryPlatform).subscribe(result => {
          // this.eventBusService.setShotsArray(result.shots);
          this.eventBusService.shots = result.shots;
        });
      } else {
        this.shotSubscription = this.shotsService.getShotsCarousel().subscribe(result => {
          // this.eventBusService.setShotsArray(result.shots);
          this.eventBusService.shots = result.shots;
        });
      }
      this.getShot(shotId);
    });
    this.eventBusSubscription = this.eventBusService.shot.subscribe(id => {
      this.getShot(id);
    });
    this.carouselShareService.shots = this.eventBusService.shots;
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }
    if (this.shotSubscription) {
      this.shotSubscription.unsubscribe();
    }
    this.eventBusSubscription.unsubscribe();
  }

  /**
   * Method to change carousel shot in modal window
   * @param shot - shot data
   */
  changeShot(shot: ShotDetail) {
    this.eventBusService.setShotData(shot);
  }

  /**
   * This method makes a request to the server about the required shot.
   * @param id - Shot ID
   */
  getShot(id) {
    let collectionId = '';
    let platform;

    this.activatedRoute.params.subscribe(params => {
      collectionId = params.collectionsId || '';
    });

    platform = collectionId ? this.platformService.selectedPlatform : '';
    this.eventBusService.setCategoryData(collectionId, platform);

    collectionId = this.eventBusService.categoryId;
    platform = this.eventBusService.platform;
    this.shotSubscription = this.shotsService.getShot(id, collectionId, platform).subscribe((result: ResShot) => {
      this.shotNext = result.next;
      this.shotPrev = result.prev;
      this.shot = result.curr;
      this.changeShot(this.shot);
      this.eventBusService.setShotData(this.shot);
    });
  }

  /**
   * This method allows you to open a form after sending a message.
   */
  openForm() {
    this.feedBackService.setSuccess(this.sendSuccess = false);
  }

  /**
   * This method implements a full description display.
   */
  showMore() {
    this.eventBusService.setFullDescription(true);
  }

  /**
   * This method hides the full description.
   */
  hideMore() {
    this.eventBusService.setFullDescription(false);
  }

  /**
   * This method implements the functionality of returning to the previous route.
   */
  backButton() {
    this.eventBusService.setFullDescription(false);
    this.feedBackService.setSuccess(this.sendSuccess = false);
    console.log('this.navService.prevRouteUrl');
    console.log(this.navService.prevRouteUrl);
    this.router.navigateByUrl(this.navService.prevRouteUrl || '/');
  }

  /**
   * This method is intended to expand the component list.
   */
  openCollapse() {
    if (!this.hiddenHireUs || !this.hiddenShowreel) {
      setTimeout(() => {
        this.targetRef.nativeElement.scrollIntoView({behavior: 'smooth'});
      }, 0);
    }
  }
}

import {Component} from '@angular/core';
import {VideoService} from '../../../_services/video/video.service';
import {CreatePopupService} from '../../../_services/create-popup/create-popup.service';

/**
 * This class implements showreel display in a modal window.
 */
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {

  /**
   * The class constructor connects the necessary services for the component to work.
   * @param videoService - service for getting data about the video file
   * @param createPopupService - service for working with a modal window
   */
  constructor(
    public videoService: VideoService,
    private createPopupService: CreatePopupService,
  ) {
  }

  /**
   * Method to close the modal window
   */
  close() {
    this.createPopupService.closeModal();
  }

}

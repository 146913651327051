import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FeedbackService} from '../../../_services/feedback/feedback.service';
import {CreatePopupService} from '../../../_services/create-popup/create-popup.service';
import {EventBusService} from '../../../_services/event-bus.service';

declare var dataLayer;
/**
 * This class is required to display the feedback form.
 */
@Component({
  selector: 'app-hire-us-form',
  templateUrl: './hire-us-form.component.html',
  styleUrls: ['./hire-us-form.component.scss']
})
export class HireUsFormComponent implements OnInit {

  /** Declaring a variable based on an instance of the FormGroup class */
  form: FormGroup;
  /** Parameter to display the error */
  errorMessage: string;
  /** Download size in bytes */
  fileSize = 15728640;
  /** Parameter to display the sending of a message */
  sendSuccess = false;
  /** Variable for file selection implemented with File interface */
  selectedFile: File;
  /** Variable to display information */
  attached = 'Attached file';
  /** Parameter to check the show form */
  showForm = true;
  /** Regular Expression Pattern for Validating Email */
  emailRegex = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.' +
    '[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param formBuilder - This class is required to create FormControl instances, which reduces form creation.
   * @param feedBackService - This service for sending information about the sending of another message
   * @param createPopupService - This service for working with a modal window
   * @param eventBusService - This service transfers data between components.
   */
  constructor(
    private formBuilder: FormBuilder,
    private feedBackService: FeedbackService,
    private createPopupService: CreatePopupService,
    private eventBusService: EventBusService,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    this.initializeForm();
    this.eventBusService.setShowForm(this.showForm);
  }

  /**
   * This method initializes form creation.
   */
  initializeForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      message: ['', Validators.required],
    });
  }

  /**
   * This method implements file selection.
   * @param event
   */
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.attached = this.selectedFile.name;
    } else {
      this.attached = 'Attached file';
    }
    if (this.attached.length > 30) {
      this.attached = `${this.attached.slice(0, 30)}...`;
    }
    if (this.selectedFile.size > this.fileSize) {
      this.errorMessage = 'Please attach file not more 15 Mb and try again';
    }
  }

  /**
   * This method implements deletion of the selected file.
   * @param file
   */
  deleteAttached(file) {
    this.selectedFile = null;
    file.value = null;
    if (!this.selectedFile) {
      this.errorMessage = '';
    }
    this.attached = 'Attached file';
  }

  /**
   * This method implements sending a message to the server through the service.
   * @param file
   */
  sendingData(file) {
    this.form.disable();
    // To test the form
    // console.log('feedback send');
    // this.feedBackService.setSuccess(this.sendSuccess = true);
    // this.sendSuccess = true;
    // file.value = null;
    this.feedBackService.postFeedback(this.form.value, this.selectedFile)
      .subscribe(
        () => {
          dataLayer.push({event: 'formsend'});
          this.feedBackService.setSuccess(true);
          this.sendSuccess = true;
          file.value = null;
          console.log('feedback send');
        },
        error => {
          this.form.enable();
          console.log(error.error.error);
          this.errorMessage = error.error.error;
        });
  }

  /**
   * This method allows you to send messages under satisfactory conditions.
   * @param file
   */
  send(file) {
    if (this.selectedFile) {
      if (this.form.valid && this.selectedFile.size <= this.fileSize) {
        this.sendingData(File);
      } else {
        this.form.enable();
        if (this.selectedFile.size > this.fileSize) {
          this.errorMessage = 'Please attach file not more 15 Mb and try again';
        } else {
          this.errorMessage = 'Please fill out the form correctly';
        }
      }
    } else {
      if (this.form.valid) {
        this.sendingData(file);
      } else {
        this.form.enable();
        this.errorMessage = 'Please fill out the form correctly';
      }
    }
  }

  /**
   * This method allows you to open a form after sending a message.
   */
  openForm() {
    this.feedBackService.setSuccess(this.sendSuccess = false);
  }
}

import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PopupComponent} from '../_shared/popup/popup.component';
import {CategoriesService} from '../../_services/categories/categories.service';
import {EventBusService} from '../../_services/event-bus.service';
import {CreatePopupService} from '../../_services/create-popup/create-popup.service';
import {ShotsService} from '../../_services/shots/shots.service';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {DiscussComponent} from '../_shared/discuss/discuss.component';
import {CookiesService} from '../../_services/cookies/cookies.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FeedbackService} from '../../_services/feedback/feedback.service';
import {ShotDetail} from '../../_interfaces/shot';
import {PlatformService} from '../../_services/platform.service';
import {Meta} from '@angular/platform-browser';
import {ShotsStoreService} from '../../_services/shots-store/shots-store.service';
import {CarouselShareService} from '../../_services/carousel-share.service';
import {PopupControlService} from '../../_services/popup-control/popup-control.service';

declare var dataLayer;

/**
 * This class implements the display of shots in a specific category.
 */
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {

  /** This parameter creates a reference link to the html element. */
  @ViewChild('portfolio') portfolioRef: ElementRef<HTMLDivElement>;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('showTip') showTipRef: ElementRef;

  // public myOptions: NgxMasonryOptions = {
  //   transitionDuration: '0.8s',
  //   itemSelector: '.masonry-item',
  //   gutter: 5,
  //   percentPosition: true,
  // };

  /** Declaring a variable based on an instance of the FormGroup class */
  form: FormGroup;
  /** Parameter to display the error */
  errorMessage: string;
  /** Variable contains information about the shot */
  shot: ShotDetail;
  /** Download size in bytes */
  fileSize = 15728640;
  /** Variable for file selection implemented with File interface */
  selectedFile: File;
  /** Variable to display information */
  attached = 'Attached file';
  /** Parameter to check the activation form */
  formActive = true;
  /** Title category */
  title: string;
  /** Description category */
  description: string;
  /** Banner category */
  banner: string;
  /** Mobile banner category */
  mobileBanner: string;
  /** Page number */
  pageNum = 1;
  /** Number of shots per page */
  perPage = 11;
  /** The number of loaded shots */
  startShot = 12;
  /** Category ID */
  categoryId: number;
  /** Category platform */
  categoryPlatform: string = this.platformService.selectedPlatform;
  /** Variable for passing information about the array of shots */
  shots: ShotDetail[] = [];
  /** Variable for passing information about the array of shots */
  shotsArr = [];
  /** Variable for passing information about the array of shotsID */
  shotsId = [];
  /** Option to create a scroll instance */
  scroll = new Subject();
  /** Parameter to check the download of all shots */
  allShotLoaded = false;
  /** Parameter to check the load of shots */
  isLoading = false;
  /** Option to display hints */
  showWalkthrough: boolean;
  /** Parameter defines random shot */
  randomShot;
  shotsCarousel = [];
  routeSubscription: Subscription;
  scrollSubscription: Subscription;

  /** Object to add fake shot to the list of shots displayed. */
  fakeShotAll = {
    title: 'Still searching?',
    description: 'Let’s discuss!',
    sm_1: '../../../assets/img/clever-full.png',
    isFake: true
  };
  /** Object to add fake shot to the list of shots displayed. */
  fakeShotMobile = {
    title: 'Still searching?',
    description: 'Let’s discuss!',
    sm_1: '../../../assets/img/clever-mobile.png',
    isFake: true
  };
  /** Object to add fake shot to the list of shots displayed. */
  fakeShotWeb = {
    title: 'Still searching?',
    description: 'Let’s discuss!',
    sm_1: '../../../assets/img/clever-web.png',
    isFake: true
  };

  /** Regular Expression Pattern for Validating Email */
  emailRegex = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.' +
    '[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param activatedRoute - Contains the information about a route associated with a component loaded in an outlet.
   * @param categoryService - Service to get category data
   * @param eventBusService - Service for transferring data between components
   * @param createPopup - Service for working with a modal window
   * @param shotsService - Service for obtaining data on shots in the category
   * @param allowCookieService - Service to get information about visiting the site
   * @param formBuilder - This class is required to create FormControl instances, which reduces form creation.
   * @param platformService - Service for transferring data between components
   * @param feedBackService - service for sending information about the sending of another message
   * @param router - An NgModule that provides navigation and URL manipulation capabilities.
   * @param meta - A service that can be used to get and add meta tags.
   * @param shotsStoreService - Service for shots store.
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoriesService,
    private eventBusService: EventBusService,
    private createPopup: CreatePopupService,
    private shotsService: ShotsService,
    private allowCookieService: CookiesService,
    private formBuilder: FormBuilder,
    private platformService: PlatformService,
    private feedBackService: FeedbackService,
    private router: Router,
    private meta: Meta,
    public shotsStoreService: ShotsStoreService,
    private carouselShareService: CarouselShareService,
    private popupControlService: PopupControlService,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    // setInterval(() => {
    //   if (document.querySelector('.portfolio-card.original')) {
    //     document.querySelectorAll('.preload__wrapper').forEach(function(element) {
    //       element.classList.add('disabled');
    //     });
    //   }
    // }, 100);
    this.initializeForm();
    if (!this.allowCookieService.walkthrough) {
      this.showWalkthrough = this.allowCookieService.walkthrough;
    } else {
      this.showWalkthrough = this.allowCookieService.tipCategory;
    }
    this.getCategoryId();
    // console.log('Проверяю позицию экрана в сервисе при иниц комп');
    // console.log(this.shotsStoreService.windowPosition);
    if (!!this.shotsStoreService.windowPosition) {
      this.shots = this.shotsStoreService.shots;
      this.pageNum = this.shotsStoreService.pageNum;
      this.startShot = this.shotsStoreService.startShotAll;
      setTimeout(() => {
        window.scroll(0, this.shotsStoreService.windowPosition);
      }, 200);
    } else {
      this.getInfoOneCategory(this.categoryId, this.categoryPlatform, this.pageNum, this.perPage);
    }
    this.getScroll();
  }

  ngOnDestroy() {
    this.shotsStoreService.windowPosition = 0;
    this.shotsStoreService.startShotAll = 0;
    this.shotsStoreService.shots = [];
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  /** Event @HostListener for window scroll  */
  @HostListener('window:scroll')
  onScroll() {
    this.scroll.next();
  }

  /**
   * The method of obtaining data when scrolling the page.
   */
  getScroll() {
    this.scrollSubscription = this.scroll
      .pipe(debounceTime(500))
      .subscribe(() => {
          const windowBotPosition: number = window.pageYOffset + window.innerHeight;
          const elemBotPosition: number = this.portfolioRef.nativeElement.offsetHeight - pageYOffset;
          if (windowBotPosition >= elemBotPosition && !this.isLoading && !this.allShotLoaded) {
            this.getInfoOneCategory(this.categoryId, this.categoryPlatform, this.pageNum, this.perPage);
          }
          // console.log('Текущая позиция экрана');
          // console.log(window.pageYOffset);
        }
      );
  }

  /**
   * The method of data transfer to the modal window
   * @param shot - shot's data
   */
  onPopup(shot: ShotDetail) {
    // console.log('Передаю позицию экрана в сервис при открытии шота');
    // console.log(window.pageYOffset);
    this.shotsStoreService.windowPosition = window.pageYOffset;
    if (shot.isFake) {
      this.onDiscuss();
    } else {
      this.getOneCategoryCarousel(shot.id);
      this.eventBusService.setShotData(shot);
      const platform = this.categoryId ? this.platformService.selectedPlatform : '';
      this.shotsService.getShot(shot.id, this.categoryId, platform).subscribe(result => {
        this.popupControlService.shotNext = result.next;
        this.popupControlService.shotPrev = result.prev;
      });
      this.eventBusService.setCategoryData(String(this.categoryId), platform);
      if (this.checkDevice()) {
        this.router.navigateByUrl(`categories/${this.categoryId}/shot/${shot.id}`).then();
      } else {
        this.createPopup.onCreatePopup(PopupComponent);
      }
    }
  }

  /**
   * Method to check user device
   */
  checkDevice() {
    return (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
      ((navigator.userAgent.search('android') > -1) &&
        (navigator.userAgent.search('mobile') > -1))) || (window.innerWidth <= 700);
  }

  /**
   * This method gets the category id from the route.
   */
  getCategoryId() {
    this.activatedRoute.params.subscribe(result => {
      this.categoryId = result.id.slice(-2);
      this.categoryPlatform = result.platform;
      if (this.categoryPlatform === undefined) {
        this.categoryPlatform = '';
        this.platformService.selectedPlatform = '';
      }
      this.getCategoryInfo(this.categoryId);
    });
  }

  /**
   * This method requests data by category.
   * @param id - Category ID
   */
  getCategoryInfo(id) {
    this.categoryService.getInfo(id).subscribe(result => {
      this.title = result.title;
      this.description = result.description;
      this.banner = result.banner;
      this.mobileBanner = result.mobile_banner;

      this.setMetaTags(result.title, result.description, result.banner);
    });
  }

  /**
   * This method sets meta tags by category.
   * @param title - Category title
   * @param description - Category description
   * @param image - Category image
   */
  private setMetaTags(title: string, description: string, image: string) {
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({property: 'og:description', content: description});
    this.meta.updateTag({property: 'og:image', content: image});
    this.meta.updateTag({property: 'og:url', content: window.location.href});
  }

  /**
   * This method is used to filter shots by platform.
   * @param platform shot's platform
   */
  switchPlatform(platform) {
    this.categoryPlatform = platform;
    this.platformService.selectedPlatform = platform;

    this.shots = [];
    this.pageNum = 1;
    this.allShotLoaded = false;
    this.getInfoOneCategory(this.categoryId, platform, this.pageNum, this.perPage);
    this.getScroll();
  }

  /**
   * This method loads shots in the selected category.
   * @param id - Category ID
   * @param platform - Platform
   * @param pageNum - Page number
   * @param perPage - Number of shots per page
   */
  getInfoOneCategory(id, platform, pageNum, perPage) {
    this.isLoading = true;
    this.categoryService.getOneCategory(id, platform, pageNum, perPage).subscribe((result) => {
      if (result.total_pages < this.pageNum) {
        this.shots = this.shots.concat(result.shots);
        this.shotsCarousel = this.shotsCarousel.concat(result.shots);
        this.allShotLoaded = true;
        return;
      }
      this.shotsCarousel = this.shotsCarousel.concat(result.shots);

      this.shotsArr = this.shots.concat(result.shots);
      if (this.shotsArr.length <= 11 || (this.shotsArr.length >= 11 && (Math.sqrt(this.shotsArr.length % 11) < 2))) {
        switch (this.categoryPlatform) {
          case 'web':
            this.shotsArr.splice(this.startShot, 0, this.fakeShotWeb);
            break;
          case 'mobile':
            this.shotsArr.splice(this.startShot, 0, this.fakeShotMobile);
            break;
          default:
            this.shotsArr.splice(this.startShot, 0, this.fakeShotAll);
        }
      }
      this.shots = this.shotsArr;
      this.shotsStoreService.shots = this.shots;
      // console.log('getInfoOneCategory', this.shots);
      result.shots.forEach(item => {
        this.shotsId.push({id: item.id, title: item.title, desc: item.description, sm_1: item.sm_1});
      });

      this.randomShot = this.shotsId[Math.floor(Math.random() * this.shotsId.length)];

      this.startShot += this.startShot;
      this.shotsStoreService.startShotAll = this.startShot;
      this.pageNum += 1;
      this.shotsStoreService.pageNum = this.pageNum;
      this.isLoading = false;
    }, error => console.log('error: ', error));
  }

  /**
   * This method implements the creation of the Discuss modal window.
   */
  onDiscuss() {
    this.createPopup.onCreatePopup(DiscussComponent);
  }

  /**
   * This method sends a list of shots to the modal carousel.
   */
  getOneCategoryCarousel(shotId: number) {
    const item = this.shotsCarousel.find(v => v.id === shotId);
    const i = this.shotsCarousel.indexOf(item);
    this.carouselShareService.customOptions.startPosition = i;
    // this.eventBusService.shots = this.shotsCarousel;
    this.carouselShareService.shots = this.shotsCarousel;
  }

  /**
   * This method is used to go to the shot from the tooltip.
   */
  toShot() {
    this.allowCookieService.setTipCategory(false);
    let collectionId = '';
    let platform;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      // console.log('Params', params);
      collectionId = params.id.slice(-2);
    });
    platform = collectionId ? this.platformService.selectedPlatform : '';

    this.eventBusService.setCategoryData(collectionId, platform);

    this.shotsService.getShot(this.randomShot.id, collectionId, platform).subscribe(result => {
      this.shot = result.curr;
      this.eventBusService.setShotData(this.shot);
      this.getOneCategoryCarousel(this.shot.id);
    });
    this.createPopup.onCreatePopup(PopupComponent);
    this.showWalkthrough = false;
  }

  /**
   * This method closes the hint.
   */
  closeTip() {
    this.allowCookieService.setTipCategory(false);
    this.showTipRef.nativeElement.style.display = 'none';
    this.allowCookieService.setVisiting();
  }

  /**
   * This method initializes the form to send a message.
   */
  initializeForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      message: ['', Validators.required],
    });
  }

  /**
   * This method implements file selection.
   * @param event
   */
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.attached = this.selectedFile.name;
    } else {
      this.attached = 'Attached file';
    }
    if (this.attached.length > 30) {
      this.attached = `${this.attached.slice(0, 30)}...`;
    }
    if (this.selectedFile.size > this.fileSize) {
      this.errorMessage = 'Please attach file not more 15 Mb and try again';
    }
    console.log(this.selectedFile);
  }

  /**
   * This method implements deletion of the selected file.
   * @param file
   */
  deleteAttached(file) {
    this.selectedFile = null;
    file.value = null;
    if (!this.selectedFile) {
      this.errorMessage = '';
    }
    this.attached = 'Attached file';
    console.log(this.selectedFile);
  }

  /**
   * This method implements sending a message to the server through the service.
   * @param file
   */
  sendingData(file) {
    // To test the form
    // this.form.disable();
    // this.formActive = false;
    // file.value = null;
    this.feedBackService.postFeedback(this.form.value, this.selectedFile)
      .subscribe(
        () => {
          dataLayer.push({event: 'formsend'});
          this.formActive = false;
          file.value = null;
        },
        error => {
          this.form.enable();
          console.log(error.error.error);
          this.errorMessage = error.error.error;
        });
  }

  /**
   * This method allows you to send messages under satisfactory conditions.
   * @param file
   */
  send(file) {
    if (this.selectedFile) {
      // console.log('1');
      if (this.form.valid && this.selectedFile.size <= this.fileSize) {
        this.sendingData(file);
      } else {
        this.form.enable();
        if (this.selectedFile.size > this.fileSize) {
          this.errorMessage = 'Please attach file not more 15 Mb and try again';
        } else {
          this.errorMessage = 'Please fill out the form correctly';
        }
      }
    } else {
      // console.log('2');
      if (this.form.valid) {
        this.sendingData(file);
      } else {
        this.form.enable();
        this.errorMessage = 'Please fill out the form correctly';
      }
    }
  }

  /**
   * This method allows you to open a form after sending a message.
   */
  openForm() {
    this.formActive = true;
    this.form.enable();
    this.form.reset();
    this.selectedFile = null;
    this.attached = 'Attached file';
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './_components/dashboard/dashboard.component';
import {CategoryComponent} from './_components/category/category.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {TermOfUseComponent} from './_components/_shared/term-of-use/term-of-use.component';
import {ShotMobileComponent} from './_components/_shared/shot-mobile/shot-mobile.component';

const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'shot/:shotId', component: ShotMobileComponent},
  {path: 'categories', component: CategoriesComponent},
  {path: 'categories/:id', component: CategoryComponent},
  {path: 'categories/:id/:platform', component: CategoryComponent},
  {path: 'categories/:collectionsId/shot/:shotId', component: ShotMobileComponent},
  {path: 'terms', component: TermOfUseComponent},
  // {path: 'shot', component: ShotMobileComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

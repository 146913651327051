import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {VideoService} from '../../_services/video/video.service';
import {DiscussComponent} from '../_shared/discuss/discuss.component';
import {CreatePopupService} from '../../_services/create-popup/create-popup.service';
import {VideoComponent} from '../_shared/video/video.component';
import {ShotsService} from '../../_services/shots/shots.service';
import {HttpParams} from '@angular/common/http';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {ShotDetail} from '../../_interfaces/shot';
import {EventBusService} from '../../_services/event-bus.service';
import {Router} from '@angular/router';
import {CookiesService} from '../../_services/cookies/cookies.service';
import {PopupComponent} from '../_shared/popup/popup.component';
import {PlatformService} from '../../_services/platform.service';
import {CarouselShareService} from '../../_services/carousel-share.service';
import {ShotsStoreService} from '../../_services/shots-store/shots-store.service';
import {PopupControlService} from '../../_services/popup-control/popup-control.service';

/**
 * This class implements the display of the Dashboard component.
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  /** This parameter creates a reference link to the html element. */
  @ViewChild('portfolio') portfolioRef: ElementRef<HTMLDivElement>;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('showTip') showTipRef: ElementRef;
  /** This parameter creates a reference link to the html element. */
  @ViewChild('video') videoRef: ElementRef;

  // public myOptions: NgxMasonryOptions = {
  //   transitionDuration: '0.8s',
  //   itemSelector: '.masonry-item',
  //   gutter: 5,
  //   // percentPosition: true,
  // };

  /** Variable for passing information about the array of shots */
  shots: ShotDetail[] = [];
  /** Variable for passing information about the array of shots */
  shotsArr = [];
  /** Option to create a scroll instance */
  scroll = new Subject();
  /** Parameter to check the download of all shots */
  allShotLoaded = false;
  /** Parameter to check the load of shots */
  isLoading = false;
  /** Page number */
  pageNum = 1;
  /** Parameter to establish the first fake shot (All) */
  startShotAll = 9;
  /** Parameter to establish the first fake shot (Web) */
  startShotWeb = 23;
  /** Parameter to establish the first fake shot (Mobile) */
  startShotMobile = 32;
  /** Default options for displaying shots */
  defaultParams = {
    tags: '',
    platform: '',
    per_page: 36
  };
  /** Object to add fake shot to the list of shots displayed. */
  fakeShotAll = {
    title: 'Still searching?',
    description: 'Let’s discuss!',
    sm_1: '../../../assets/img/clever-full.png',
    isFake: true
  };
  /** Object to add fake shot to the list of shots displayed. */
  fakeShotMobile = {
    title: 'Still searching?',
    description: 'Let’s discuss!',
    sm_1: '../../../assets/img/clever-mobile.png',
    isFake: true
  };
  /** Object to add fake shot to the list of shots displayed. */
  fakeShotWeb = {
    title: 'Still searching?',
    description: 'Let’s discuss!',
    sm_1: '../../../assets/img/clever-web.png',
    isFake: true
  };
  /** Option to display hints */
  showWalkthrough: boolean;
  shotsCarousel = [];
  scrollSubscription: Subscription;
  getShotSubscription: Subscription;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param createPopup - Service for working with a modal window
   * @param videoService -
   * @param shotsService - Service for obtaining data on shots in the category
   * @param eventBusService - Service for transferring data between components
   * @param router - An NgModule that provides navigation and URL manipulation capabilities.
   * @param allowCookieService - Service to get information about visiting the site
   * @param platformService - Service for transferring data between components
   * @param carouselShareService - Service implements the expansion of the functional carousel shots
   * @param shotsStoreService - Service for shots store
   */
  constructor(
    private createPopup: CreatePopupService,
    public videoService: VideoService,
    private shotsService: ShotsService,
    private eventBusService: EventBusService,
    private router: Router,
    private allowCookieService: CookiesService,
    private platformService: PlatformService,
    private carouselShareService: CarouselShareService,
    private popupControlService: PopupControlService,
    public shotsStoreService: ShotsStoreService,
  ) {
  }

  /**
   * This lifecycle interface is used during component initialization.
   */
  ngOnInit() {
    // console.log('Проверяю позицию экрана в сервисе при иниц комп');
    // console.log(this.shotsStoreService.windowPosition);
    if (!!this.shotsStoreService.windowPosition) {
      this.shots = this.shotsStoreService.shots;
      this.pageNum = this.shotsStoreService.pageNum;
      this.startShotAll = this.shotsStoreService.startShotAll;
      this.startShotWeb = this.shotsStoreService.startShotWeb;
      this.startShotMobile = this.shotsStoreService.startShotMobile;
      setTimeout(() => {
        window.scroll(0, this.shotsStoreService.windowPosition);
      }, 200);
    } else {
      this.getShots();
    }
    if (!this.allowCookieService.walkthrough) {
      this.showWalkthrough = this.allowCookieService.walkthrough;
    } else {
      this.showWalkthrough = this.allowCookieService.tipHeader;
    }
    this.scrollSubscription = this.scroll
      .pipe(debounceTime(500))
      .subscribe(() => {
        const windowBotPosition: number = window.pageYOffset + window.innerHeight;
        const elemBotPosition: number = this.portfolioRef.nativeElement.offsetHeight - pageYOffset;
        if (windowBotPosition >= elemBotPosition && !this.isLoading && !this.allShotLoaded) {
          this.getShots();
        }
        // console.log('Текущая позиция экрана');
        // console.log(window.pageYOffset);
      });
    // console.log(this.shots);
  }

  ngOnDestroy() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    if (this.getShotSubscription) {
      this.getShotSubscription.unsubscribe();
    }
  }

  /** Event @HostListener for video pause  */
  @HostListener('pause')
  videoPlay() {
    const videoEl = this.videoRef.nativeElement;
    videoEl.play();
  }

  /**
   * This method implements the creation of the Discuss modal window.
   */
  onDiscuss() {
    this.createPopup.onCreatePopup(DiscussComponent);
  }

  /**
   * This method implements the creation of a modal window Showreel
   */
  onVideo() {
    this.createPopup.onCreatePopup(VideoComponent);
  }

  /** Event @HostListener for window scroll  */
  @HostListener('window:scroll')
  onScroll() {
    this.scroll.next();
  }

  /**
   * This method sends a list of shots to the modal carousel.
   */
  getShotCarousel(shotId: number) {
    const item = this.shotsCarousel.find(v => v.id === shotId);
    const i = this.shotsCarousel.indexOf(item);
    this.carouselShareService.customOptions.startPosition = i;
    // this.eventBusService.shots = this.shotsCarousel;
    this.carouselShareService.shots = this.shotsCarousel;
  }

  /**
   * This method loads shots.
   */
  getShots() {
    let params = new HttpParams();
    params = params.append('page', `${this.pageNum}`);
    params = params.append('tags', `${this.defaultParams.tags}`);
    params = params.append('platform', `${this.defaultParams.platform}`);
    params = params.append('per_page', `${this.defaultParams.per_page}`);
    // after switching on the parameter on mobile devices after closing the popup-control, all shots are opened in full screen
    // params = params.append('cached', 'true');
    this.isLoading = true;
    this.getShotSubscription = this.shotsService.getShots(params).subscribe(res => {
      if (res.total_pages < this.pageNum) {
        this.shots = this.shots.concat(res.shots);
        this.shotsCarousel = this.shotsCarousel.concat(res.shots);
        this.allShotLoaded = true;
        return;
      }
      this.shotsCarousel = this.shotsCarousel.concat(res.shots);

      this.shotsArr = this.shots.concat(res.shots);
      // console.log(this.shotsArr.length);
      this.shotsArr.splice(this.startShotAll, 0, this.fakeShotAll);
      this.shotsArr.splice(this.startShotWeb, 0, this.fakeShotWeb);
      this.shotsArr.splice(this.startShotMobile, 0, this.fakeShotMobile);
      this.shots = this.shotsArr;
      this.shotsStoreService.shots = this.shots;

      this.startShotAll = this.startShotAll + this.defaultParams.per_page;
      this.startShotWeb = this.startShotWeb + this.defaultParams.per_page;
      this.startShotMobile = this.startShotMobile + this.defaultParams.per_page;

      this.pageNum += 1;
      this.shotsStoreService.pageNum = this.pageNum;
      this.shotsStoreService.startShotAll = this.startShotAll;
      this.shotsStoreService.startShotWeb = this.startShotWeb;
      this.shotsStoreService.startShotMobile = this.startShotMobile;
      this.isLoading = false;
    }, error => console.log('error: ', error));
  }

  /**
   * This method is used to go to the categories from the tooltip.
   */
  toCategories() {
    this.allowCookieService.setTipHeader(false);
    this.showWalkthrough = false;
    this.router.navigateByUrl(`/categories`).then();
  }

  /**
   * This method closes the hint.
   */
  closeTip() {
    this.allowCookieService.setTipHeader(false);
    this.showTipRef.nativeElement.style.display = 'none';
    this.allowCookieService.setVisiting();
  }

  /**
   * The method of data transfer to the modal window
   * @param shot - shot's data
   */
  onPopup(shot: ShotDetail) {
    // console.log('Передаю позицию экрана в сервис при открытии шота');
    // console.log(window.pageYOffset);
    this.shotsStoreService.windowPosition = window.pageYOffset;
    if (shot.isFake) {
      this.onDiscuss();
    } else {
      this.getShotCarousel(shot.id);
      this.eventBusService.setShotData(shot);
      const collectionId = '';
      const platform = collectionId ? this.platformService.selectedPlatform : '';
      this.getShotSubscription = this.shotsService.getShot(shot.id, collectionId, platform).subscribe(result => {
        this.popupControlService.shotNext = result.next;
        this.popupControlService.shotPrev = result.prev;
      });
      this.eventBusService.setCategoryData(collectionId, platform);
      if (this.checkDevice()) {
        this.router.navigateByUrl(`shot/${shot.id}`).then();
      } else {
        this.createPopup.onCreatePopup(PopupComponent);
      }
    }
  }

  /**
   * Method to check user device
   */
  checkDevice() {
    return (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
      ((navigator.userAgent.search('android') > -1) &&
        (navigator.userAgent.search('mobile') > -1))) || (window.innerWidth <= 700);
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

/**
 * This class implements sending subscriber information.
 */
@Injectable({
  providedIn: 'root'
})
export class SubscribeService {

  /** Url to the API. */
  apiURL = environment.apiUrl;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param httpClient - This class is required to create http requests.
   */
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Method to send subscriber information
   * @param subscribe - subscriber e-mail
   */
  postSubscribe(subscribe) {
    // https://api.mobile.design/api/subscribers
    return this.httpClient.post(`${this.apiURL}/api/subscribers`, subscribe);
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {ShotDetail} from '../../../../_interfaces/shot';

@Component({
  selector: 'app-fake-shot',
  templateUrl: './fake-shot.component.html',
  styleUrls: ['./fake-shot.component.scss'],
})
export class FakeShotComponent implements OnInit {

  @Input() shot: ShotDetail;

  constructor() { }

  ngOnInit() {
  }

}

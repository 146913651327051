import {Component, Input} from '@angular/core';
import {fade} from '../../../_animations/fade';
import {ShotDetail} from '../../../_interfaces/shot';

/**
 * This class implements the display of shots.
 */
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent {

  /** Variable for a list of shots */
  @Input() shot: ShotDetail;

}

import {Injectable} from '@angular/core';

/**
 * This service implements the transfer of parameters of routes between components.
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  /** Parameter of the previous route */
  prevRouteUrl: string;
  /** Parameter of the current route */
  currentRouteUrl: string;

}

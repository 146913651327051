import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatInputModule, MatDialogModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
  ],
  exports: [
    MatDialogModule,
    MatInputModule,
  ]
})
export class MaterialModule {
}

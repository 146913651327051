import {Injectable} from '@angular/core';
import {EventBusService} from '../event-bus.service';
import {ShotsService} from '../shots/shots.service';
import {ResShot, ShotDetail, ShotImage} from '../../_interfaces/shot';

/**
 * This class implements the control of switching shots in a modal window.
 */
@Injectable({
  providedIn: 'root'
})
export class PopupControlService {

  /** This parameter contains information about the next shot. */
  shotNext;
  /** This parameter contains information about the previous shot. */
  shotPrev;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param eventBus - This service transfers data between components.
   * @param shotsService - This service sends a request for shots to the server.
   */
  constructor(
    private eventBus: EventBusService,
    private shotsService: ShotsService,
  ) {
  }

  /**
   * Method to change carousel shot in modal window
   * @param shot - shot data
   */
  changeShot(shot: ShotDetail) {
    this.eventBus.setFullDescription(false);
    this.eventBus.setShotData(shot);
    // this.showMoreTip();

  }

  /**
   * This method is required to switch shots.
   * @param shot - Contains information about the next or previous shot
   */
  onChangeShot(shot: ShotDetail) {
    this.changeShot(shot);
    this.getShot(shot.id);
    this.eventBus.setFullDescription(false);
  }

  /**
   * This method makes a request to the server about the required shot.
   * @param id - Shot ID
   */
  getShot(id: number) {
    let collectionId: string;
    let platform: string;
    let shotId: number;
    let shot: ShotDetail;

    collectionId = this.eventBus.categoryId;
    platform = this.eventBus.platform;
    shotId = id;

    this.shotsService.getShot(shotId, collectionId, platform).subscribe((result: ResShot) => {
      this.shotNext = result.next;
      this.shotPrev = result.prev;
      shot = result.curr;

      this.changeShot(shot);
    });
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';


/**
 * This class implements sending messages from the contact form.
 */
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  /** Url to the API. */
  apiURL = environment.apiUrl;

  /** Parameter for transmitting the sending status of the message (default is false) */
  success = false;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param httpClient - This class is required to create http requests.
   */
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Method for notification of successful message sending
   * @param success - true or false
   */
  setSuccess(success) {
    this.success = success;
  }

  /**
   * Method for sending a message from the contact form
   * @param feedback - message body
   * @param file - attached file to the message
   */
  postFeedback(feedback, file?: File) {
    // https://api.mobile.design/api/feedbacks
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('email', feedback.email);
    formData.append('message', feedback.message);
    formData.append('name', feedback.name);
    return this.httpClient.post(`${this.apiURL}/api/feedbacks`, formData);
    // return this.httpClient.post(`${this.apiURL}/api/fakeapi`, formData);
  }
}

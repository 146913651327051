import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {EMPTY, merge, Observable, of} from 'rxjs';
import {RequestCachingService} from '../_services/request-caching/request-caching.service';
import {catchError, filter, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

/**
 * This class implements data caching in session storage.
 */
@Injectable({
  providedIn: 'root'
})
export class CachingInterceptor implements HttpInterceptor {

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param requestCachingService - This service is required for the operation of the query interceptor
   */
  constructor(
    private requestCachingService: RequestCachingService
  ) {
  }

  /**
   * Method to implement query interceptor
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.params.has('cached')) {
      return next.handle(req);
    }

    const storageKey = this.requestCachingService.getRequestKey(req);
    const cachedData = this.requestCachingService.getDataFromSessionStorage(storageKey);
    const isCachedDataValid = !!cachedData;

    if (isCachedDataValid) {
      return of(new HttpResponse<any>(cachedData));
    }

    this.requestCachingService.setHttpStoryMade(storageKey);

    return merge(
      (!cachedData) ? EMPTY : of(new HttpResponse<any>(cachedData)),
      next.handle(req).pipe(
        catchError(() => isCachedDataValid ? of(new HttpResponse<any>(cachedData)) : null),
        filter(data => data && !!data['body']),
        tap(data => {
          this.requestCachingService.saveDataInSessionStorage(storageKey, data);
        }),
      ),
    );
    return next.handle(req);
  }

}

import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {CreatePopupService} from '../../../_services/create-popup/create-popup.service';
import {FeedbackService} from '../../../_services/feedback/feedback.service';

/**
 * This class implements the display of a modal window for sending a message.
 */
@Component({
  selector: 'app-discuss',
  templateUrl: './discuss.component.html',
  styleUrls: ['./discuss.component.scss']
})
export class DiscussComponent implements OnInit, OnDestroy {

  /** Option to open contact form */
  sendSuccess: boolean;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param createPopupService - service for working with a modal window
   * @param feedBackService - service for sending information about the sending of another message
   * @param renderer - extend this base class to implement custom rendering.
   */
  constructor(
    private createPopupService: CreatePopupService,
    public feedBackService: FeedbackService,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this.feedBackService.setSuccess(this.sendSuccess = false);
  }

  ngOnDestroy() {
    this.close();
  }

  /**
   * Method to close the modal window
   */
  close() {
    this.openForm();
    this.renderer.removeClass(document.body, 'modal-open');
    this.createPopupService.closeModal();
  }

  /**
   * Method for opening contact form
   */
  openForm() {
    this.feedBackService.setSuccess(this.sendSuccess = false);
  }

}

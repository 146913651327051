import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Video} from '../../_interfaces/video';

/**
 * This class implements information and video data retrieval.
 */
@Injectable({
  providedIn: 'root'
})
export class VideoService {

  /** Url to the API. */
  apiUrl = environment.apiUrl;

  /** Variable contains information about the showreel video file. */
  video: string;

  /** Variable contains information about the main video file. */
  mainVideo: string;
  showreelVideo: string;

  /**
   * The class constructor connects the necessary services, modules, classes for the component to work.
   * @param httpClient - This class is required to create http requests.
   */
  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * Method to get the main video
   */
  getMainVideo() {
    let params = new HttpParams();
    params = params.append('cached', 'true');
    return this.httpClient.get<Video>(`${this.apiUrl}/api/videos`, {params}).subscribe(videoArray => {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < videoArray.length; i++) {
        switch (videoArray[i].places[0]) {
          case 'main':
            this.mainVideo = videoArray[i].video;
            // console.log(videoArr[i]);
            break;
          case 'showreel':
            this.showreelVideo = videoArray[i].video;
            // console.log(videoArr[i]);
            break;
          default:
            console.log('Video not found');
        }
      }
    });
  }

}

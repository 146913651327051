import {Component, Input, OnInit} from '@angular/core';
import {ShotDetail} from '../../../../_interfaces/shot';

@Component({
  selector: 'app-video-shot',
  templateUrl: './video-shot.component.html',
  styleUrls: ['./video-shot.component.scss']
})
export class VideoShotComponent implements OnInit {

  @Input() shot: ShotDetail;

  constructor() { }

  ngOnInit() {
  }

}

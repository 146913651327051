/* Modules */
import {BrowserModule, Meta} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {Event, NavigationEnd, Router} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './_material/material.module';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {NgxMasonryModule} from 'ngx-masonry';
/* Directives */
import {FreezeGifDirective} from './_directives/freeze-gif.directive';
import {ClickOutsideDirective} from './_directives/click-out-side.directive';
import {FreezeVideoDirective} from './_directives/freeze-video.directive';
/* Services */
import {CookieService} from 'ngx-cookie-service';
/* Interceptors */
import {CachingInterceptor} from './_interceptors/caching.interceptor';
/* Components */
import {AppComponent} from './app.component';
import {HeaderComponent} from './_components/_shared/header/header.component';
import {FooterComponent} from './_components/_shared/footer/footer.component';
import {DashboardComponent} from './_components/dashboard/dashboard.component';
import {HireUsFormComponent} from './_components/_shared/hire-us-form/hire-us-form.component';
import {CategoryComponent} from './_components/category/category.component';
import {DiscussComponent} from './_components/_shared/discuss/discuss.component';
import {VideoComponent} from './_components/_shared/video/video.component';
import {PopupComponent} from './_components/_shared/popup/popup.component';
import {CollapseComponent} from './_components/_shared/collapse/collapse.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {AboutUsComponent} from './_components/_shared/about-us/about-us.component';
import {SinglecategoryComponent} from './components/single-category/single-category.component';
import {SubscribeComponent} from './_components/subscribe/subscribe.component';
import {GalleryComponent} from './_components/_shared/gallery/gallery.component';
import {TermOfUseComponent} from './_components/_shared/term-of-use/term-of-use.component';
import {ShotMobileComponent} from './_components/_shared/shot-mobile/shot-mobile.component';
import {NavigationService} from './_services/navigation.service';
import { DefaultShotComponent } from './_components/_shared/shot-types/default-shot/default-shot.component';
import { VideoShotComponent } from './_components/_shared/shot-types/video-shot/video-shot.component';
import { FakeShotComponent } from './_components/_shared/shot-types/fake-shot/fake-shot.component';
import { ScrollToTopComponent } from './_components/scroll-to-top/scroll-to-top.component';
import { ShotCarouselComponent } from './_components/shot-carousel/shot-carousel.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    HireUsFormComponent,
    CategoryComponent,
    DiscussComponent,
    VideoComponent,
    PopupComponent,
    CollapseComponent,
    CategoriesComponent,
    AboutUsComponent,
    SinglecategoryComponent,
    SubscribeComponent,
    GalleryComponent,
    TermOfUseComponent,
    FreezeGifDirective,
    FreezeVideoDirective,
    ClickOutsideDirective,
    ShotMobileComponent,
    DefaultShotComponent,
    VideoShotComponent,
    FakeShotComponent,
    ScrollToTopComponent,
    ShotCarouselComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    NgxMasonryModule,
    CarouselModule
  ],
  entryComponents: [
    DiscussComponent,
    VideoComponent,
    PopupComponent,
    AboutUsComponent,
    SubscribeComponent,
    // TermOfUseComponent,
  ],
  providers: [CookieService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router,
              private navService: NavigationService,
              private meta: Meta) {
    this.restorePageMetaTags(router, meta);
  }

  private restorePageMetaTags(router: Router, meta: Meta) {
    router.events.subscribe((event: Event, ) => {

      if (event instanceof NavigationEnd) {
        this.navService.prevRouteUrl = this.navService.currentRouteUrl;
        this.navService.currentRouteUrl = event.urlAfterRedirects;
      }

      if (event instanceof NavigationEnd) {
        meta.updateTag({property: 'og:title', content: 'Web and Mobile App Design Agency. Development Company | Mobile.Design'});
        meta.updateTag({
          property: 'og:description',
          content: 'Mobile.Design is a group of professionals and enthusiasts who designs and develops high level mobile friendly websites and apps for iOS and Android.'
        });
        meta.updateTag({property: 'og:image', content: 'https://mobile.design/assets/img/mobile-design.jpg'});
        meta.updateTag({property: 'og:url', content: 'https://mobile.design'});
      }

    });
  }
}



import {Injectable} from '@angular/core';

/**
 * This service implements the transfer of information about the selected platform among the components.
 */
@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  /** Variable for transferring information about the selected platform */
  selectedPlatform: string = '';

}

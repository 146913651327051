import {Component, HostListener, Renderer2} from '@angular/core';
import {DiscussComponent} from '../discuss/discuss.component';
import {CreatePopupService} from '../../../_services/create-popup/create-popup.service';
import {MatDialogRef} from '@angular/material';

/**
 * This class implements the display of the modal window about us
 */
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  /**
   * The class constructor connects the necessary services, modules for the component to work.
   * @param createPopup - service for working with a modal window
   * @param renderer - extend this base class to implement custom rendering.
   * @param dialogRef - class for modal window functionality
   */
  constructor(
    private createPopup: CreatePopupService,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<AboutUsComponent>
  ) {
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.renderer.removeClass(document.body, 'modal-open');
  }

  /**
   * Method to open the message sending form
   */
  onDiscuss() {
    this.createPopup.onCreatePopup(DiscussComponent);
    this.dialogRef.close();
  }

  /**
   * Method to close the modal window
   */
  close() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.createPopup.closeModal();
  }

}
